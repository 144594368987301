
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonBackButton,
  IonContent,
  IonSpinner,
  IonIcon,
  /*   IonList,
  IonItem, */
  //IonLabel,
  IonText,
  IonButtons,
  IonBadge,
  onIonViewWillEnter,
  //IonFab,
  //IonFabButton,
} from "@ionic/vue";

import { refresh, chevronForward } from "ionicons/icons";

import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import apiTickets from "../services/ticket";
import { openToast } from "../services/toast";

export default {
  name: "FreeTickets",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonBackButton,
    IonContent,
    IonPage,
    IonSpinner,
    IonIcon,
    /*     IonList,
    IonItem, */
    //IonLabel,
    IonText,
    IonButtons,
    IonBadge,
    //IonFab,
    //IonFabButton,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const tickets = ref([]);

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }

    //passing id to retrive detail in FreeTicketDetail page
    function openDetail(id: string) {
      router.push(`free-ticket-detail/${id}`);
    }

    function getFreeTickets() {
      loading.value = true;
      apiTickets
        .getFreeTickets()
        .then((response) => {
          //console.log(response);
          tickets.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta dei tickets", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }
    //getFreeTickets();

    //Fix per ricaricare dati ogni volta che apro la pagina
    onIonViewWillEnter(() => {
      getFreeTickets();
    });

    /*     onMounted(() => {
      getFreeTickets();
    }); */

    return {
      loading,
      tickets,
      riferimentoCliente,
      openDetail,
      refresh,
      chevronForward,
    };
  },
};
